import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useState } from "react";

const useHandleLogin = () => {
  const [isError, setIsError] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const router = useRouter();

  const handleLogin = async (formData) => {
    setIsError(false);
    setIsWorking(true);

    const result = await signIn("credentials", {
      email: formData.email,
      password: formData.password,
      redirect: false,
    });

    if (result.status === 200) {
      router.replace("/");
    } else {
      setIsError(true);
      setIsWorking(false);
    }
  };

  return {
    isError,
    isWorking,
    handleLogin,
  };
};

export default useHandleLogin;
