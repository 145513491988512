import React from "react";

import { SvgIcon } from "@mui/material";

const EarthdawnIcon = (props) => (
  <SvgIcon viewBox="0 0 148.000000 148.000000">
    <g transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M697 1285 c-65 -22 -105 -63 -146 -150 -45 -93 -59 -143 -71 -252
-21 -195 -10 -342 34 -470 59 -166 132 -243 233 -243 114 0 189 86 249 287 25
81 27 102 27 278 l1 190 -37 110 c-21 60 -44 117 -52 126 -8 8 -15 20 -15 26
0 12 -69 76 -97 91 -33 17 -88 20 -126 7z m106 -19 c78 -34 149 -146 187 -301
20 -80 22 -107 17 -255 -8 -282 -72 -445 -199 -511 -53 -27 -89 -24 -143 14
-104 70 -185 276 -185 470 0 44 5 62 20 77 12 12 18 26 14 32 -4 7 -3 8 4 4 7
-4 12 -24 12 -44 0 -47 15 -102 35 -127 16 -20 16 -20 7 0 -13 27 -13 154 0
178 15 29 41 57 53 57 10 0 38 50 31 57 -1 2 -22 -11 -46 -28 -33 -24 -44 -28
-51 -18 -5 9 2 25 23 46 25 26 28 33 15 33 -27 0 -38 42 -16 58 27 19 63 -2
57 -32 -4 -22 -3 -22 27 -13 l32 10 -38 38 c-33 33 -39 45 -39 82 0 117 129
177 210 96 59 -60 48 -157 -23 -195 -15 -9 -11 -13 27 -31 43 -19 45 -19 35
-2 -13 25 5 59 31 59 28 0 52 -38 37 -61 -6 -10 -15 -15 -21 -12 -5 4 -6 1 -1
-7 6 -10 4 -12 -11 -6 -11 4 -2 -10 22 -36 23 -24 44 -50 48 -58 11 -29 -6 76
-25 155 -59 235 -188 328 -301 218 -44 -43 -55 -63 -97 -180 -41 -114 -52
-131 -44 -68 7 62 42 150 81 207 67 97 137 128 215 94z m144 -340 c-3 -7 0
-19 6 -26 8 -8 5 -8 -10 1 -26 16 -29 25 -10 32 19 8 19 8 14 -7z"
      />
      <path
        d="M668 1181 c-28 -25 -33 -36 -33 -75 0 -35 6 -51 24 -68 l23 -23 -7
22 c-14 50 -8 83 20 113 32 34 74 39 99 14 22 -21 20 -42 -6 -81 -26 -37 -62
-44 -78 -14 -7 15 -5 25 10 43 11 12 19 18 20 13 0 -6 -5 -16 -11 -22 -13 -13
-4 -33 15 -33 16 0 46 42 46 64 0 20 -32 36 -57 30 -29 -8 -56 -60 -48 -92 19
-80 73 -96 131 -38 47 47 47 95 0 142 -46 46 -101 48 -148 5z"
      />
      <path d="M595 989 c-4 -6 -3 -15 3 -21 6 -6 11 -4 15 6 7 18 -9 31 -18 15z" />
      <path d="M895 989 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
      <path d="M683 922 c-7 -4 8 -7 33 -7 24 0 44 3 44 8 0 9 -61 9 -77 -1z" />
      <path d="M782 921 c-9 -5 -2 -8 20 -9 23 -1 28 2 18 8 -18 12 -22 12 -38 1z" />
      <path
        d="M670 875 c-7 -8 -9 -15 -6 -15 4 0 14 7 22 15 9 8 11 15 6 15 -5 0
-15 -7 -22 -15z"
      />
      <path d="M820 876 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
      <path
        d="M601 821 c-21 -21 -38 -68 -28 -77 11 -11 27 8 27 31 0 13 7 31 16
40 9 9 14 18 11 21 -3 3 -15 -4 -26 -15z"
      />
      <path
        d="M655 830 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"
      />
      <path
        d="M830 830 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
-4 -4 -10z"
      />
      <path
        d="M880 821 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"
      />
      <path
        d="M627 793 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3 -9
2 -12 -2z"
      />
      <path
        d="M929 785 c0 -11 0 -41 1 -66 1 -36 -4 -52 -25 -77 -15 -18 -33 -32
-40 -32 -15 0 -28 -27 -19 -41 3 -5 2 -8 -3 -7 -4 1 -19 -4 -32 -11 -17 -9
-44 -12 -89 -8 -35 4 -62 4 -59 1 12 -13 118 -15 151 -4 80 26 136 103 136
187 0 49 -19 100 -21 58z"
      />
      <path d="M875 760 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M596 731 c-3 -5 6 -11 19 -14 29 -5 43 8 19 17 -22 8 -32 8 -38 -3z" />
      <path
        d="M901 735 c-2 -5 -4 -20 -5 -33 -2 -13 -10 -31 -20 -40 -16 -16 -18
-16 -36 8 -14 18 -20 21 -20 9 0 -9 8 -24 19 -33 18 -16 20 -16 46 6 22 19 26
30 22 58 -2 19 -5 31 -6 25z"
      />
      <path
        d="M810 719 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
      />
      <path
        d="M583 678 c4 -32 25 -68 39 -68 6 0 3 9 -6 19 -19 21 -14 41 9 41 8 0
17 5 21 12 4 6 3 8 -4 4 -6 -3 -13 -2 -17 4 -3 5 -15 10 -26 10 -15 0 -19 -6
-16 -22z"
      />
      <path
        d="M780 674 c1 -12 39 -61 40 -51 0 5 -9 20 -20 34 -11 14 -20 22 -20
17z"
      />
      <path
        d="M964 609 c-30 -65 -113 -127 -177 -130 -20 -1 -20 -2 -2 -6 25 -7 73
-61 81 -91 12 -45 -4 -96 -38 -125 -43 -37 -73 -43 -121 -26 -49 17 -77 52
-84 106 -6 42 12 80 52 115 11 10 15 17 9 18 -23 0 -135 82 -149 108 l-14 27
0 -30 c0 -80 48 -221 99 -290 93 -126 213 -101 286 60 33 71 76 228 72 262
l-3 28 -11 -26z m-39 -143 c0 -40 -27 -56 -54 -32 -18 17 -26 61 -9 50 4 -3 8
-1 8 3 0 5 13 9 28 8 24 0 28 -4 27 -29z m-298 8 c8 -22 -10 -49 -32 -49 -10
0 -22 7 -28 16 -15 24 -1 49 28 49 15 0 28 -7 32 -16z"
      />
      <path d="M880 454 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
      <path d="M580 454 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
      <path
        d="M612 577 c19 -18 34 -24 50 -19 21 5 20 7 -9 13 -18 3 -41 12 -50 19
-10 7 -6 1 9 -13z"
      />
      <path d="M786 585 c-16 -12 -17 -15 -4 -15 9 0 18 7 22 15 7 18 6 18 -18 0z" />
      <path
        d="M691 436 c-32 -18 -50 -53 -51 -99 -1 -109 162 -136 205 -34 14 34 5
91 -18 114 -12 13 -14 8 -9 -36 4 -47 2 -53 -32 -87 -28 -28 -40 -34 -58 -29
-58 18 -65 65 -18 110 25 24 34 27 48 19 23 -15 27 -48 7 -64 -17 -14 -21 0
-4 17 13 13 4 33 -15 33 -13 0 -56 -53 -56 -70 0 -6 12 -15 26 -21 30 -11 65
6 84 40 11 22 -11 99 -33 113 -18 11 -49 9 -76 -6z"
      />
    </g>
  </SvgIcon>
);

export default EarthdawnIcon;
