import React from "react";

import { SvgIcon } from "@mui/material";

const DungeonWorldIcon = (props) => (
  <SvgIcon viewBox="0 0 187.000000 193.000000" {...props}>
    <g transform="translate(0.000000,193.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M612 1884 c-18 -14 -39 -39 -47 -55 l-15 -29 -253 5 c-250 5 -252 5
-199 -13 82 -26 99 -52 107 -164 4 -51 5 -235 3 -408 -3 -272 -6 -319 -20
-346 -18 -32 -70 -64 -106 -64 -12 0 -24 -4 -27 -10 -8 -12 225 -13 415 0 190
12 318 59 453 166 64 51 63 57 -13 83 -30 10 -36 9 -48 -6 -7 -10 -38 -44 -70
-76 -78 -80 -190 -136 -289 -144 -94 -8 -117 6 -132 77 -14 65 -15 708 -1 781
15 77 39 99 111 99 l58 0 -6 -47 c-4 -37 1 -67 22 -128 29 -86 29 -122 0 -189
-20 -46 -32 -39 -50 31 l-12 48 -15 -30 c-39 -75 -34 -157 13 -228 34 -52 95
-97 130 -97 36 0 113 52 148 100 67 92 76 194 30 330 l-22 64 -13 -61 c-20
-97 -29 -106 -43 -42 -7 30 -29 81 -51 114 -45 69 -64 115 -53 126 13 13 156
-17 216 -45 63 -30 110 -79 140 -145 42 -92 24 -285 -39 -414 -13 -28 -24 -52
-24 -54 0 -2 34 -3 75 -3 l75 0 30 53 c51 89 75 179 75 292 0 84 -3 107 -22
141 -57 107 -190 168 -422 194 -52 6 -97 13 -99 15 -2 2 3 25 12 51 9 26 15
49 13 50 -1 2 -17 -8 -35 -22z m69 -362 c17 -35 20 -52 14 -103 -7 -57 -6 -60
12 -51 28 15 50 43 70 87 l18 40 3 -39 c5 -53 -16 -170 -34 -193 -14 -17 -15
-17 -10 7 2 14 8 37 11 53 8 32 -5 35 -30 7 -23 -25 -51 -26 -65 0 -6 10 -13
46 -17 80 l-6 61 -41 -88 c-30 -67 -40 -101 -41 -140 0 -31 -4 -52 -10 -50 -5
2 -23 22 -39 45 -23 32 -31 56 -34 102 -2 33 0 60 4 60 5 0 17 -18 27 -40 10
-22 22 -40 25 -40 4 0 25 39 46 87 35 80 38 92 33 152 l-5 66 24 -30 c13 -17
34 -49 45 -73z"
      />
      <path
        d="M414 1073 c4 -4 26 -15 49 -23 51 -19 67 -47 67 -124 l0 -58 33 6
c17 3 52 16 77 29 40 20 46 27 52 66 9 54 20 67 66 82 77 24 53 29 -148 29
-112 0 -200 -3 -196 -7z"
      />
      <path
        d="M920 1071 c0 -5 15 -12 33 -15 19 -3 45 -18 58 -32 l24 -26 5 -316
c5 -316 5 -317 31 -367 14 -27 31 -61 37 -75 7 -16 11 -19 11 -9 1 9 16 47 35
85 l34 69 4 307 3 306 24 26 c13 14 39 29 58 32 80 15 19 24 -162 24 -107 0
-195 -4 -195 -9z"
      />
      <path
        d="M1424 1073 c4 -4 26 -15 49 -23 61 -23 67 -41 67 -220 0 -273 -38
-360 -194 -454 -32 -19 -83 -41 -113 -47 -61 -14 -66 -20 -98 -124 l-21 -70
-12 47 c-6 25 -20 66 -31 90 -17 41 -23 46 -61 52 -106 17 -215 97 -264 195
-32 61 -56 159 -56 225 0 45 -1 48 -22 42 -13 -3 -49 -11 -80 -17 -58 -12 -58
-12 -58 -48 1 -77 30 -197 65 -268 58 -118 177 -207 350 -263 95 -31 110 -43
144 -111 16 -33 32 -56 34 -52 2 5 10 25 16 45 17 50 70 94 139 114 144 41
301 152 354 251 47 87 61 161 67 376 6 192 7 199 29 218 13 10 39 21 58 25 81
15 20 24 -168 24 -111 0 -198 -3 -194 -7z"
      />
    </g>
  </SvgIcon>
);

export default DungeonWorldIcon;
