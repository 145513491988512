import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

const formSchema = yup
  .object()
  .shape({
    email: yup.string().email("Must be a valid email").max(255).required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

export default yupResolver(formSchema);
