import mergeSx from "@/common/utils/mergeSx";
import { Container, Paper } from "@mui/material";

const LoginContainer = ({ sx, children, ...restProps }) => {
  const defaultSx = {
    padding: 4,
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  const finalSx = mergeSx(defaultSx, sx);

  return (
    <Container sx={finalSx} maxWidth="sx" component={Paper} {...restProps}>
      {children}
    </Container>
  );
};

export default LoginContainer;
