import { getSession } from "next-auth/react";

import PlainLayout from "@/layouts/PlainLayout";
import LoginPageContent from "@/modules/auth/pages/LoginPage";

const LoginPage = () => {
  return <LoginPageContent />;
};

LoginPage.getLayout = (page) => <PlainLayout>{page}</PlainLayout>;

export default LoginPage;

export const getServerSideProps = async (context) => {
  const session = await getSession({ req: context.req });

  if (!!session) {
    // Already logged in!
    return { redirect: { destination: "/", permanent: false } };
  }

  return { props: {} };
};
