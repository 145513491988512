import { FaDice, FaTasks, FaTheaterMasks } from "react-icons/fa";
import {
  GiArmorVest,
  GiAura,
  GiAxeSword,
  GiBatteredAxe,
  GiDaggers,
  GiKnapsack,
  GiLightBackpack,
  GiMagicPalm,
  GiPoisonBottle,
  GiQuill,
  GiSkills,
  GiSpellBook,
  GiTemplarShield,
  GiWizardStaff,
} from "react-icons/gi";

import DungeonWorld from "./components/DungeonWorldIcon";
import Earthadawn from "./components/EarthdawnIcon";

const size = "1.75rem";

export const OverviewIcon = (props) => <GiAura size={size} {...props} />;
export const AttributesIcon = (props) => <GiSkills size={size} {...props} />;
export const RoleplayingIcon = (props) => <FaTheaterMasks size={size} {...props} />; //  alt: FaDualityMask
export const MovesIcon = (props) => <FaDice size={size} {...props} />;
export const SpellsIcon = (props) => <GiWizardStaff size={size} {...props} />;
export const GearIcon = (props) => <GiLightBackpack size={size} {...props} />; //          alt: GiChest
export const NotesIcon = (props) => <GiQuill size={size} {...props} />; //         alt: GiQuill
export const ExtrasIcon = (props) => <FaTasks size={size} {...props} />;

export const AdventurerIcon = (props) => <GiKnapsack size={size} {...props} />;
export const DisciplieIcon = (props) => <GiTemplarShield size={size} {...props} />;
export const MagicianIcon = (props) => <GiSpellBook size={size} {...props} />;
export const RogueIcon = (props) => <GiDaggers size={size} {...props} />;
export const WarriorIcon = (props) => <GiBatteredAxe size={size} {...props} />;

export const AllSpellsIcon = (props) => <GiMagicPalm size={size} {...props} />;

export const WeaponGearIcon = (props) => <GiAxeSword size={size} {...props} />;
export const ArmorGearIcon = (props) => <GiArmorVest size={size} {...props} />;
export const AdventuringGearIcon = (props) => <GiLightBackpack size={size} {...props} />;
export const PoisonGearIcon = (props) => <GiPoisonBottle size={size} {...props} />;

export const DungeonWorldIcon = DungeonWorld;
export const EarthdawnIcon = Earthadawn;
