import FlexBox from "@/common/components/elements/FlexBox";
import { DungeonWorldIcon } from "@/common/components/elements/Icons";
import { Alert, Typography } from "@mui/material";

import LoginContainer from "./components/LoginContainer";
import LoginForm from "./components/LoginForm";
import useHandleLogin from "./hooks/useHandleLogin";

// TODO: forgot password/signup

const LoginPage = () => {
  const { isError, isWorking, handleLogin } = useHandleLogin();

  return (
    <LoginContainer>
      <FlexBox sx={{ mb: 2 }}>
        <DungeonWorldIcon fontSize="large" />
        <Typography component="h1" variant="h1">
          Beyond
        </Typography>
      </FlexBox>

      <Typography component="h1" variant="h4" sx={{ mb: 1 }}>
        Sign in
      </Typography>

      {isError && (
        <Alert severity="error" variant="filled" sx={{ my: 2, width: "100%" }}>
          Invalid email or password
        </Alert>
      )}

      <LoginForm onSubmit={handleLogin} isWorking={isWorking} />
      {/* <Box>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box> */}
    </LoginContainer>
  );
};

export default LoginPage;
